import { Layout } from '@postscript/components';
import styled from 'styled-components';

const StyledLayout = styled(Layout).attrs({
  vertical: true,
})`
  & > div,
  // Supports nested Layout component for side-by-side fields
  & > div > div:has(input) {
    width: 100%;
  }

  button[type='submit'] {
    margin-top: var(--spacing-2);
  }
`;

interface Props {
  children: React.ReactNode;
}

export default function FormLayout({ children }: Props): JSX.Element {
  return <StyledLayout>{children}</StyledLayout>;
}
