import { DISCLAIMER_PRIVACY_TAG, DISCLAIMER_TERMS_TAG } from './sdk/constants';
import { Disclaimer } from './sdk/types';

export const ROUTE_POPUPS_BLOCK = '/block-popups';
export const SUBROUTE_CREATE = '/create';
export const SUBROUTE_EDITOR = '/editor';
export const SUBROUTE_LIST = '/list';
export const BLOCK_POPUPS_QUERY_KEY_PREFIX = 'block-popups';
export const EDITOR_STATIC_DISCLAIMER: Disclaimer = {
  text: `*By providing your phone number, you agree to receive recurring automated marketing text messages (e.g. cart reminders) from this shop and third parties acting on its behalf. Consent is not a condition to obtain goods or services. Msg & data rates may apply. Msg frequency varies. Reply HELP for help and STOP to cancel. You also agree to the ${DISCLAIMER_TERMS_TAG} and ${DISCLAIMER_PRIVACY_TAG}`,
  terms_of_service: { text: 'Terms of Service', url: '#' },
  privacy_policy: { text: 'Privacy Policy', url: '#' },
};
