import PageHeader from 'components/layout/PageHeader';
import OmniDashboard from 'components/omni/OmniDashboard';

const Performance = () => {
  return (
    <>
      <PageHeader pageTitle="Performance" />
      <OmniDashboard id="75a878d1" />
    </>
  );
};

export default Performance;
