/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */

import { toast, ToastContainer } from '@postscript/components';
import Profile from 'components/account/AccountView/users/profile/Profile';
import SdkAppExtensionBanner from 'components/account/SdkAppExtensionBanner';
import { FEATURE_KEYS } from 'components/billing/common/constants';
import BillingBanners from 'components/billing/common/prompts/BillingBanners';
import RacApprovedConfirmation from 'components/billing/common/prompts/RacApprovedConfirmation';
import Plans from 'components/billing/modules/selfMigrate/Plans';
import GorgiasIntegrationV2 from 'components/integrations/GorgiasIntegrationV2';
import Loading from 'components/invisible/Loading';
import LookerDashboard from 'components/looker/LookerDashboard';
import DataSyncingBanner from 'components/notifications/DataSyncingBanner';
import TFNVerificationStatusBanner from 'components/notifications/TFNVerificationStatusBanner';
import OmniDashboards from 'components/omni/OmniDashboards';
import { ROUTE_POPUPS_BLOCK } from 'components/popups/constants';
import { LabsFeatureContext } from 'controllers/contexts/labsFeatures';
import { createBrowserHistory } from 'history';
import { SentryContext } from 'hooks/useSentry';
import useShopData from 'hooks/useShopData';
import React, { Component, Suspense, useEffect } from 'react';
import cookie from 'react-cookies';
import 'react-dates/initialize';
import ReactGA from 'react-ga';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import { ThemeProvider } from 'styled-components';
import {
  OMNI_SANDBOX,
  OMNI_TRENDS,
  USAGE_BILLING_ENABLED,
} from './components/admin/utils/feature-flags';
import ResetForgottenPassword from './components/authentication/ResetForgottenPassword';
import ResetPasswordInivisible from './components/authentication/ResetPasswordInvisible';
import ResetPasswordRequest from './components/authentication/ResetPasswordRequest';
import CallForwardingBanner from './components/billing/common/prompts/CallForwardingBanner';
import LegacyPastDueStatusBanner from './components/billing/common/prompts/LegacyPastDueStatusBanner';
import { UsageBillingContext } from './components/billing/context/usageBilling';
import GlobalAuthenticatedContexts from './components/Global/GlobalAuthenticatedContexts';
import YCLogin from './components/invisible/YCLogin';
import AppShell from './components/layout/AppShell';
import LegacyGlobalBannerList from './components/LegacyGlobalBanners/LegacyGlobalBannerList';
import AppModal from './components/navigation/AppModal';
import UpsellModal from './components/navigation/UpsellModal';
import DelightedRatingComponent from './components/notifications/DelightedRatingComponent';
import OnboardingStatusBanner from './components/pspay/OnboardingStatusBanner';
import ReferralRedirect from './components/referrals/ReferralRedirect';
import SMSSalesMerchantRoutes from './components/sales/MerchantRoutes';
import { SALES_ROUTE_PREFIX } from './components/sales/Routes';
import './config/styles';
import { HistoryContext, UserContext } from './controllers/contexts';
import { FeatureFlagsContext } from './controllers/contexts/featureFlags';
import { LegacyGlobalBannersProvider } from './controllers/contexts/legacyGlobalBanners';
import { PopupsProvider } from './controllers/contexts/popups';
import { PsPayProvider } from './controllers/contexts/pspay';
import Events from './controllers/events';
import Requests from './controllers/network/network_requests';
import './controllers/prototypes';
import Utils from './controllers/utils';
import { feedbackLoopRedirect } from './providers';
import { base } from './themes/themes';
import { initSentry } from './utils/config';
import { ENV, STRIPE_PUBLIC_KEY } from './utils/envVars';
import { lazyLoadWithChunkRefresh } from './utils/lazyLoadWithChunkRefresh';
import { setZendeskShopTag, updateZendeskJWT } from './utils/zendeskUtils';

const AppInit = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/invisible/AppInit'),
    'AppInit',
  ),
);
const AppAuth = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/invisible/AppAuth'),
    'AppAuth',
  ),
);
const LoggedOutTemplatePreview = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/automations/LoggedOutTemplatePreview'),
    'LoggedOutTemplatePreview',
  ),
);
const RegisterForm = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/authentication/RegisterUser'),
    'RegisterForm',
  ),
);
const LoginUserShopify = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/authentication/LoginUserShopify'),
    'LoginUserShopify',
  ),
);
const LoginForm = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/authentication/LoginUser'),
    'LoginUser',
  ),
);
const SegmentView = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/segments/SegmentView'),
    'SegmentView',
  ),
);
const SegmentSubscriberView = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/segments/subscribers/SegmentSubscriberView'),
    'SegmentSubscriberView',
  ),
);
const KeywordsView = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/keywords/KeywordsView'),
    'KeywordsView',
  ),
);
const ResponsesView = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/responses/ResponsesView'),
    'ResponsesView',
  ),
);
const IdeasView = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/ideas/IdeasView'),
    'IdeasView',
  ),
);
const IntegrationsView = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/integrations/IntegrationsView'),
    'IntegrationsView',
  ),
);
const NotificationsView = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/notifications/NotificationsView'),
    'NotificationsView',
  ),
);
const AdminRoutes = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/admin/Routes'),
    'AdminRoutes',
  ),
);
const AccountReviewView = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/admin/AccountReviewView'),
    'AccountReviewView',
  ),
);
const SuperuserView = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/admin/superuser'),
    'SuperuserView',
  ),
);
const GrowListView = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/grow/GrowListView'),
    'GrowListView',
  ),
);
const AnalyticsRoutes = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/analytics/AnalyticsRoutes'),
    'AnalyticsRoutes',
  ),
);
const FlowsRoutes = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/flowBuilder/FlowsRoutes'),
    'FlowsRoutes',
  ),
);
const BillingRoutes = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/billing/Routes'),
    'BillingRoutes',
  ),
);
const SubscriberListsRoutes = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/subscribersLists/components/Routes'),
    'SubscribersRoutes',
  ),
);
const SubscriberListUploadsRoutes = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () =>
      import(
        './components/subscribersLists/components/SubscriberListUploadsRoutes'
      ),
    'SubscriberListUploadsRoutes',
  ),
);
const BrandRoutes = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/brand/Routes'),
    'BrandRoutes',
  ),
);
const PopupRoutes = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/Popup/Routes'),
    'PopupRoutes',
  ),
);
const PopupBlockRoutes = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/popups/Routes'),
    'PopupBlockRoutes',
  ),
);
const CheckoutSettingsView = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/checkout/CheckoutSettingsView'),
    'CheckoutSettingsView',
  ),
);
const AccountView = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/account/AccountView'),
    'AccountView',
  ),
);
const ContactView = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/contacts/ContactView'),
    'ContactView',
  ),
);
const DashboardViewEntry = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/dashboard/DashboardViewEntry'),
    'DashboardViewEntry',
  ),
);
const AutomationsRoutes = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/automations/Routes'),
    'AutomationsRoutes',
  ),
);
const PsPayRoutes = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/pspay/Routes'),
    'PsPayRoutes',
  ),
);
const RedirectPage = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/pspay/RedirectPage/RedirectPage'),
    'RedirectPage',
  ),
);
const CampaignsView = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/campaigns/CampaignsView'),
    'CampaignsView',
  ),
);
const OnboardingView = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/onboarding/OnboardingView'),
    'OnboardingView',
  ),
);
const ReferralsView = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./components/referrals/ReferralsView'),
    'ReferralsView',
  ),
);
const RunTemplateView = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import(`./components/GenericTemplates/RunTemplateView`),
    'RunTemplateView',
  ),
);

const SalesRoutes = React.lazy(() =>
  lazyLoadWithChunkRefresh(() => import('./components/sales/Routes')),
);

const ShopData = () => {
  const { data, isLoading } = useShopData();
  useEffect(() => {
    if (data) {
      window.dataLayer.push({
        event: 'gtm data loaded',
        data,
      });
    }
  }, [isLoading]);
  return null;
};

const history = createBrowserHistory();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

if (ENV !== 'production' && window) {
  window.GlobRequests = Requests;
}

const initialState = {
  appState: window.location.href.split('/')[3],
  appModalObj: null,
  needsRating: false,
  showNotificationsBar: true,
  updatedIntercom: false,
  upsellModal: false,
  user: false,
  billing: null,
  shops: [],
  isFetchingShops: false,
};

if (ENV === 'production') {
  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.componentDidMount = this.componentDidMount.bind(this);
    this.logOut = this.logOut.bind(this);
    this.setGenericState = this.setGenericState.bind(this);
    this.getBillingInfo = this.getBillingInfo.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
    this.changeAppState = this.changeAppState.bind(this);
    this.updateAppModal = this.updateAppModal.bind(this);
    this.checkUserRatings = this.checkUserRatings.bind(this);
    this.checkRevenueForRatings = this.checkRevenueForRatings.bind(this);
    this.updateUserInfo = this.updateUserInfo.bind(this);
    this.updateShops = this.updateShops.bind(this);

    let sentry;

    if (window.location.href.includes('app.postscript')) {
      this.state.env = 'prod';
      ReactGA.initialize('UA-119147544-2');
      const sentryAPI = initSentry('production');
      sentry = sentryAPI;
    } else if (window.location.href.includes('dev.postscript')) {
      this.state.env = 'dev';
      const sentryAPI = initSentry('development');
      sentry = sentryAPI;
      ReactGA.initialize('UA-119147544-3');
    } else if (window.location.href.includes('localhost')) {
      this.state.env = 'local';
      ReactGA.initialize('UA-119147544-4');
    }

    this.sentry = sentry;
  }

  componentDidMount() {
    if (window.location.href.includes('/init')) {
      cookie.remove('refresh_token');
      cookie.remove('access_token');
    }
    Utils.checkIfLoggedIn().then(
      function (result) {
        this.setState(
          {
            loggedIn: result,
          },
          () => {
            if (result) updateZendeskJWT();
            this.getUserInfo();
          },
        );
      }.bind(this),
    );
  }

  // Allow lower level components to modify user data.
  updateUserInfo(data) {
    const { user } = this.state;
    // If the user has not been initialized yet, return.
    if (!user) return;

    const newUser = { ...user, ...data };
    this.setState({ user: newUser });
  }

  updateShops() {
    const { user, isFetchingShops, shops } = this.state;
    // If the user has not been initialized yet, we're not logged in, return
    if (!user || isFetchingShops || shops.length) return;

    this.setState(
      {
        isFetchingShops: true,
      },
      () => {
        Requests.get('/admin/get_all_shops?limit=30').then(
          function (result) {
            if (result && result.shops) {
              this.setState({
                isFetchingShops: false,
                shops: result.shops,
              });
            }
          }.bind(this),
        );
      },
    );
  }

  checkRevenueForRatings() {
    Requests.get('/shop/has_revenue').then(
      function (result) {
        if (result && result.success) {
          if (result.has_revenue) {
            // If they have generated revenue within
            // 30 days of install, send activated event
            // This is allowed to fire multiple times per user
            // since we don't track firing on the backend
            // TODO: Track activation events on the backend
            // and don't fire multiple times

            const thirty_days = new Date();
            thirty_days.setDate(thirty_days.getDate() - 30);
            const installed_at_date = new Date(this.state.user.installed_at);
            if (installed_at_date > thirty_days) {
              Events.track('Account Activated - 30 Days');
            }
          }
          this.setState(
            {
              revenueEarned: result.has_revenue,
            },
            () => {
              if (!this.state.user) return;
              let usage = 0;
              let percentageUsed = 0;
              let allowed = 0;
              if (this.state.user) {
                if (this.state.user.texts_sent_this_month) {
                  usage = this.state.user.texts_sent_this_month;
                }
                if (
                  this.state.user.billing_period_record &&
                  this.state.user.billing_period_record.number_of_texts
                ) {
                  usage = this.state.user.billing_period_record.number_of_texts;
                }
                if (
                  this.state.user.plan &&
                  this.state.user.plan.monthly_texts_allowed
                ) {
                  allowed = this.state.user.plan.monthly_texts_allowed;
                  percentageUsed = Math.floor((usage / allowed) * 100);
                }
                if (
                  this.state.user.billing_period_record &&
                  this.state.user.billing_period_record.number_of_texts_allowed
                ) {
                  allowed =
                    this.state.user.billing_period_record
                      .number_of_texts_allowed;
                  percentageUsed = Math.floor((usage / allowed) * 100);
                }
              }
              let daysRemainingInPeriod = 0;
              let daysInPeriod = 0;
              if (
                this.state.user &&
                this.state.user.billing_period_record &&
                this.state.user.billing_period_record.billing_period_end_time &&
                this.state.user.billing_period_record.billing_period_start_time
              ) {
                daysInPeriod = Math.floor(
                  (new Date(
                    this.state.user.billing_period_record.billing_period_end_time,
                  ) -
                    new Date(
                      this.state.user.billing_period_record.billing_period_start_time,
                    )) /
                    (1000 * 3600 * 24),
                );
                daysRemainingInPeriod = Math.floor(
                  (new Date(
                    this.state.user.billing_period_record.billing_period_end_time,
                  ) -
                    new Date()) /
                    (1000 * 3600 * 24),
                );
              }
              if (
                this.state.user &&
                !this.state.user.is_admin &&
                this.state.user.plan &&
                this.state.user.free_trial &&
                !this.state.user.free_trial.active &&
                !this.state.featureFlags?.[USAGE_BILLING_ENABLED] &&
                ((percentageUsed >= 50 &&
                  this.state.user.plan.price === '$0.00') ||
                  (percentageUsed >= 90 && daysRemainingInPeriod >= 5) ||
                  (percentageUsed >= 80 && daysRemainingInPeriod >= 10) ||
                  (percentageUsed >= 70 && daysRemainingInPeriod >= 15))
              ) {
                this.setState({
                  upsellModal: true,
                  percentageOfCreditsUsed: percentageUsed,
                  daysRemainingInPeriod,
                });
              } else {
                this.checkUserRatings();
              }
            },
          );
        }
      }.bind(this),
    );
  }

  checkUserRatings() {
    // if user has a certain amount of revenue and hasn't left a review ever or in the past 1-2 months
    if (!this.state.user) return;
    const path = '/postscript_rating';
    Requests.get(path).then(
      function (result) {
        if (result) {
          let needsRating = false;
          const ratings = result.ratings ? result.ratings : [];
          let latestRating = null;
          let leftReview = false;
          let leftFeedback = false;
          if (ratings.length > 0) {
            ratings.forEach((r) => {
              if (
                r.app_store_review ||
                (this.state.user &&
                  this.state.user.data &&
                  this.state.user.data.shopify_review)
              )
                leftReview = true;
              if (r.feedback && r.feedback.length > 0) leftFeedback = true;
              const createdDate = new Date(r.created_at);
              if (createdDate && (createdDate > latestRating || !latestRating))
                latestRating = createdDate;
            });
            if (
              new Date() - latestRating > 30 * 24 * 60 * 60 * 1000 &&
              !leftReview
            )
              needsRating = true;
          } else {
            needsRating = true;
          }
          if (!this.state.revenueEarned) needsRating = false;
          this.setState({
            needsRating,
            ratings,
          });
        }
      }.bind(this),
    );
  }

  setGenericState(obj) {
    this.setState(obj);
  }

  changeAppState(name) {
    this.setState({
      appState: name,
    });
  }

  updateAppModal(modalObj) {
    this.setState({
      appModalObj: modalObj,
    });
  }

  getBillingInfo(user = null) {
    user = user || this.state.user;
    if (user) {
      return Requests.get('/shops/billings')
        .then((result) => {
          if (result && result.data) {
            return new Promise((resolve) => {
              this.setState(
                (cur) => {
                  return {
                    ...cur,
                    billing: result.data,
                  };
                },
                () => {
                  resolve(result.data);
                },
              );
            });
          }
          return Promise.resolve(null);
        })
        .catch(() => {
          return Promise.resolve(null);
        });
    }
    return Promise.resolve(null);
  }

  getFeatureFlagInfo() {
    return Requests.get('/shop/feature_flags')
      .then((result) => {
        if (result && result.data) {
          return new Promise((resolve) => {
            this.setState(
              (cur) => {
                return {
                  ...cur,
                  featureFlags: result.data,
                };
              },
              () => {
                resolve(result.data);
              },
            );
          });
        }
        return Promise.resolve(null);
      })
      .catch(() => {
        return Promise.resolve(null);
      });
  }

  getUserInfo(callback, refetchShops = true) {
    if (this.state.loggedIn) {
      Requests.get('/me')
        .then((result) => {
          if (result && result.data) {
            return result;
          }

          return null;
        })
        .then(
          function (result) {
            if (result && result.data) {
              if (
                this.state.user &&
                !this.state.user.has_synced &&
                result.data.has_synced == true &&
                result.data.has_onboarded
              ) {
                this.updateAppModal({
                  header: 'Sync Finished!',
                  body: "Your shop's customer and order data have been synced to Postscript. You're all set to create campaigns with granular segmentation based on your subscribers' order history.",
                  button: 'Got It',
                });
              }
              if (result.data.shop_statistics)
                result.data.shop_statistics = null;
              if (result.data.username) {
                Events.initialize(result.data.username);
                // Full Story Identification
                // Ignore admins
                const metadata = result.data;
                metadata.displayName = result.data.shop_name;

                this.getFeatureFlagInfo().then(() => {
                  if (this.state.featureFlags != null)
                    metadata.featureFlags = this.state.featureFlags;
                });

                // GTM Data Layer
                if (window.dataLayer) {
                  window.dataLayer.push(metadata);
                  window.dataLayer.push({
                    event: 'User Data Loaded',
                  });
                }
              }

              // Add shop and shop id tags to Zendesk chat session
              if (result.data.shop && result.data.shop_id) {
                setZendeskShopTag([
                  result.data.shop,
                  `shop_id-${result.data.shop_id}`,
                ]);
              }

              // Convert date types.
              if (result.data.free_trial && result.data.free_trial.end_date) {
                result.data.free_trial.end_date = new Date(
                  result.data.free_trial.end_date,
                );
              }
              if (!result.data.short_link_domain) {
                result.data.short_link_domain = 'pscr.pt';
              }

              // load the billing data before assigning user data
              this.getBillingInfo(result.data).then(() => {
                this.setState(
                  {
                    user: this.enrichUser(result.data),
                  },
                  () => {
                    if (refetchShops) this.updateShops();

                    if (callback) callback();
                    if (typeof this.state.revenueEarned !== 'boolean')
                      this.checkRevenueForRatings();
                  },
                );
              });
              if (
                result.data.announcement &&
                result.data.announcement.announcement
              ) {
                this.updateAppModal({
                  header: result.data.announcement.announcement.headline,
                  body: result.data.announcement.announcement.body,
                  button: result.data.announcement.announcement.cta_text,
                  path: result.data.announcement.announcement.cta_path,
                  media_url:
                    result.data.announcement.announcement.cta_media_url,
                  announcement_id: result.data.announcement.id,
                });
              }
            }
          }.bind(this),
        );
    }
  }

  handleUpdate() {
    Events.track('Upgrade App Clicked');
    Requests.get('/shop/update_permissions').then(function (result) {
      if (result && result.permission_url) {
        Events.track('Upgrade App Success');
        window.location.href = result.permission_url;
      }
      if (result && result.error) {
        Events.track('Upgrade App Error');
        toast.error(result.error);
      }
    });
  }

  /**
   * Determines whether a user can provider feedback, based on plan
   * price
   */
  userCanProvideIdeas(user, minimumIdeasPrice = 100) {
    if (user && user.plan && user.plan.price) {
      const planPrice = user.plan.price;
      const parsedPrice = parseFloat(planPrice.replace('$', ''));
      return parsedPrice >= minimumIdeasPrice;
    }

    return false;
  }

  /**
   * Add derived attributes to the user object
   */
  enrichUser(user) {
    return {
      ...user,
      canProvideIdeas: this.userCanProvideIdeas(user),
    };
  }

  logOut() {
    Events.track('Logged Out');
    Utils.logOut().then(
      function () {
        this.setState({
          loggedIn: false,
          shops: [],
        });
        window.location.replace('/login');
      }.bind(this),
    );
  }

  render() {
    const { user, shops, isFetchingShops } = this.state;
    const {
      location: { pathname: path },
    } = window;

    return (
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={base}>
          <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
            <HistoryContext.Provider value={history}>
              <SentryContext.Provider value={this.sentry}>
                <Router history={history}>
                  <ToastContainer />
                  {this.state.loggedIn ? (
                    this.state.user ? (
                      // Logged In View
                      !this.state.user.has_onboarded &&
                      !history.location.pathname.includes('onboarding') &&
                      // Hack: avoid redirecting to onboarding if user is visiting PSPay checkout page
                      !history.location.pathname.includes('pspay-customer') ? (
                        <Redirect to="/onboarding" />
                      ) : (
                        <GlobalAuthenticatedContexts
                          user={user}
                          shops={shops}
                          isFetchingShops={isFetchingShops}
                          getUserInfo={this.getUserInfo}
                          updateUserInfo={this.updateUserInfo}
                          updateShops={this.updateShops}
                        >
                          {this.state.needsRating &&
                          this.state.user &&
                          !this.state.user.is_admin ? (
                            <DelightedRatingComponent user={this.state.user} />
                          ) : null}
                          {this.state.appModalObj ? (
                            <AppModal
                              modalObj={this.state.appModalObj}
                              updateAppModal={this.updateAppModal}
                            />
                          ) : null}
                          {this.state.upsellModal ? (
                            <UpsellModal
                              user={this.state.user}
                              closeModal={() => {
                                this.setState({ upsellModal: false });
                              }}
                              percentageUsed={
                                this.state.percentageOfCreditsUsed
                              }
                              daysRemainingInPeriod={
                                this.state.daysRemainingInPeriod
                              }
                            />
                          ) : null}
                          <ShopData />
                          <OnboardingStatusBanner />
                          <DataSyncingBanner user={this.state.user} />
                          <CallForwardingBanner />
                          <LegacyPastDueStatusBanner />
                          <RacApprovedConfirmation />
                          <BillingBanners />
                          <SdkAppExtensionBanner />
                          <TFNVerificationStatusBanner />
                          {window.location.href.split('/').length < 5 &&
                          window.location.href.split('/')[3].length < 1 ? (
                            <Redirect from="/" to="/dashboard" />
                          ) : null}
                          <Suspense
                            fallback={
                              <AppShell
                                appUpdateHandler={this.handleUpdate}
                                appState={this.state.appState}
                                changeAppState={this.changeAppState}
                                getUserInfo={this.getUserInfo}
                                user={this.state.user}
                                shops={shops}
                                logOut={this.logOut}
                                inSuspense
                              />
                            }
                          >
                            <Switch>
                              <Route
                                key="sms-sales-merchant"
                                path="/sms-sales"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <SMSSalesMerchantRoutes />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key="subscriber-list-uploads"
                                path="/subscriber-list-uploads"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <SubscriberListUploadsRoutes />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key="sms-sales-looker-dashboard"
                                path="/sms-sales-dashboard"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <LookerDashboard id={230} />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key="test-omni-dashboard"
                                path="/omni"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <OmniDashboards
                                      title="Omni Dashboards"
                                      featureKey={OMNI_SANDBOX}
                                      adminOnly
                                    />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key="trends"
                                path="/trends"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <OmniDashboards
                                      title="Trends"
                                      featureKey={OMNI_TRENDS}
                                    />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key="admin-list-management"
                                path="/admin/list-management"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <SubscriberListsRoutes />
                                  </AppShell>
                                )}
                              />
                              <Route
                                exact
                                key="redirect-page"
                                path="/pspay-customer/:uniqueKey"
                                render={(props) => <RedirectPage {...props} />}
                              />
                              <Route path="/shopify/init" component={AppInit} />
                              <Route
                                key={1}
                                path="/dashboard"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <PopupsProvider>
                                      <DashboardViewEntry
                                        changeAppState={this.changeAppState}
                                        getUserInfo={this.getUserInfo}
                                        user={this.state.user}
                                        updateAppModal={this.updateAppModal}
                                        showNotificationsBar={
                                          this.state.showNotificationsBar
                                        }
                                        closeNotificationsBar={() => {
                                          this.setState({
                                            showNotificationsBar: false,
                                          });
                                        }}
                                      />
                                    </PopupsProvider>
                                  </AppShell>
                                )}
                              />
                              <Route
                                key={2}
                                path="/campaigns"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <CampaignsView
                                      {...props}
                                      user={this.state.user}
                                      updateAppModal={this.updateAppModal}
                                      changeAppState={this.changeAppState}
                                    />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key="segment subscriber view"
                                path="/segments/:segmentId/subscribers"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <SegmentSubscriberView {...props} />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key={3}
                                path="/segments"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <SegmentView
                                      changeAppState={this.changeAppState}
                                      user={this.state.user}
                                      updateAppModal={this.updateAppModal}
                                    />
                                  </AppShell>
                                )}
                              />
                              <Route exact path="/reports">
                                <Redirect to="/account/reports" />
                              </Route>
                              <Route
                                key={4}
                                path="/automations"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <AutomationsRoutes
                                      user={this.state.user}
                                      updateAppModal={this.updateAppModal}
                                      changeAppState={this.changeAppState}
                                      {...props}
                                    />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key={5}
                                path="/onboarding"
                                render={({ match }) => (
                                  <LabsFeatureContext.Consumer>
                                    {({ hasLabsFlag }) => (
                                      <FeatureFlagsContext.Consumer>
                                        {({ search }) => (
                                          <UsageBillingContext.Consumer>
                                            {({ enableUsageBilling }) => (
                                              <OnboardingView
                                                user={this.state.user}
                                                getUserInfo={this.getUserInfo}
                                                match={match}
                                                enableUsageBilling={
                                                  enableUsageBilling
                                                }
                                                getFeatureFlags={search}
                                                hasLabsFlag={hasLabsFlag}
                                              />
                                            )}
                                          </UsageBillingContext.Consumer>
                                        )}
                                      </FeatureFlagsContext.Consumer>
                                    )}
                                  </LabsFeatureContext.Consumer>
                                )}
                              />
                              <Route
                                key={12}
                                path="/keywords"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <UsageBillingContext.Consumer>
                                      {(usageBillingData) => {
                                        const {
                                          hasPackageFeature,
                                          currentPlan,
                                        } = usageBillingData;

                                        if (
                                          currentPlan &&
                                          !hasPackageFeature({
                                            featureKey: FEATURE_KEYS.keywords,
                                            currentPlan,
                                          })
                                        ) {
                                          return <Redirect to="/dashboard" />;
                                        }

                                        return (
                                          <KeywordsView
                                            user={this.state.user}
                                            updateAppModal={this.updateAppModal}
                                            changeAppState={this.changeAppState}
                                            usageBillingData={usageBillingData}
                                          />
                                        );
                                      }}
                                    </UsageBillingContext.Consumer>
                                  </AppShell>
                                )}
                              />
                              <Route
                                key={13}
                                path="/responses/:id?"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <ResponsesView
                                      {...props}
                                      user={this.state.user}
                                      updateAppModal={this.updateAppModal}
                                      changeAppState={this.changeAppState}
                                      getUserInfo={this.getUserInfo}
                                    />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key={162}
                                path="/pspay"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <PsPayProvider>
                                      <PsPayRoutes {...props} />
                                    </PsPayProvider>
                                  </AppShell>
                                )}
                              />
                              <Route
                                key={15}
                                path="/grow"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <GrowListView
                                      user={this.state.user}
                                      updateAppModal={this.updateAppModal}
                                      changeAppState={this.changeAppState}
                                    />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key={5}
                                path="/analytics"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <AnalyticsRoutes />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key="flows"
                                path="/flows"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <FlowsRoutes />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key="self-migrate-usage-billing-plans"
                                path="/billing/switch-to-usage"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <Plans />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key="usage-billing"
                                path="/billing"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <BillingRoutes />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key="brand"
                                path="/brand"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <BrandRoutes />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key="user-profile"
                                path="/profile"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <Profile />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key={6}
                                path="/account"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <AccountView
                                      location={props.location}
                                      user={this.state.user}
                                      getUserInfo={this.getUserInfo}
                                    />
                                  </AppShell>
                                )}
                              />
                              {this.state.user.canProvideIdeas && (
                                <Route
                                  key={23}
                                  exact
                                  path="/ideas"
                                  render={(props) => (
                                    <AppShell
                                      {...props}
                                      appState={this.state.appState}
                                      appUpdateHandler={this.handleUpdate}
                                      changeAppState={this.changeAppState}
                                      getUserInfo={this.getUserInfo}
                                      user={this.state.user}
                                      shops={shops}
                                      logOut={this.logOut}
                                    >
                                      <IdeasView />
                                    </AppShell>
                                  )}
                                />
                              )}
                              <Route
                                key={16}
                                path="/checkout"
                                render={({ match, ...props }) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <CheckoutSettingsView
                                      user={this.state.user}
                                      getUserInfo={this.getUserInfo}
                                      changeAppState={this.changeAppState}
                                      updateAppModal={this.updateAppModal}
                                      match={match}
                                    />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key={1000}
                                path="/integrations/zendesk/oauth_authorize"
                                component={() => {
                                  window.location.href = `https://api.postscript.io/integrations/zendesk/oauth_authorize?${window.location.search.substr(
                                    1,
                                  )}`;
                                  return null;
                                }}
                              />
                              <Route
                                key="gorgias-integration"
                                path="/integrations/gorgias"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <GorgiasIntegrationV2 />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key={17}
                                path="/integrations"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <IntegrationsView
                                      user={this.state.user}
                                      getUserInfo={this.getUserInfo}
                                      changeAppState={this.changeAppState}
                                    />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key={18}
                                path="/notifications"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <NotificationsView
                                      user={this.state.user}
                                      getUserInfo={this.getUserInfo}
                                      changeAppState={this.changeAppState}
                                    />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key="sudo me please"
                                path="/account-overview/sudo"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <SuperuserView match={props.match} />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key={19}
                                path="/account-overview"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <AccountReviewView
                                      user={this.state.user}
                                      ratings={this.state.ratings}
                                      getUserInfo={this.getUserInfo}
                                      updateUserInfo={this.updateUserInfo}
                                      changeAppState={this.changeAppState}
                                    />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key={20}
                                path="/popups"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <PopupRoutes />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key={25}
                                path={ROUTE_POPUPS_BLOCK}
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <PopupBlockRoutes />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key={21}
                                path="/customers/:id?"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <ContactView
                                      {...props}
                                      changeAppState={this.changeAppState}
                                      user={this.state.user}
                                      updateAppModal={this.updateAppModal}
                                      queryClient={queryClient}
                                    />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key={22}
                                path="/subscribers/:id?"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <ContactView
                                      {...props}
                                      changeAppState={this.changeAppState}
                                      user={this.state.user}
                                      updateAppModal={this.updateAppModal}
                                      queryClient={queryClient}
                                    />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key={24}
                                path="/referrals"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <ReferralsView />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key="admin"
                                path="/admin"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <AdminRoutes />
                                  </AppShell>
                                )}
                              />
                              <Route
                                path="/shopify/auth"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <AppAuth
                                      loggedIn
                                      getUserInfo={this.getUserInfo}
                                    />
                                  </AppShell>
                                )}
                              />
                              <Route
                                path="/t/:id"
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <RunTemplateView
                                      id={props.match.params.id}
                                    />
                                  </AppShell>
                                )}
                              />
                              <Route
                                key="sales"
                                path={SALES_ROUTE_PREFIX}
                                render={(props) => (
                                  <AppShell
                                    {...props}
                                    appState={this.state.appState}
                                    appUpdateHandler={this.handleUpdate}
                                    changeAppState={this.changeAppState}
                                    getUserInfo={this.getUserInfo}
                                    user={this.state.user}
                                    shops={shops}
                                    logOut={this.logOut}
                                  >
                                    <SalesRoutes />
                                  </AppShell>
                                )}
                              />
                              <Redirect to="/dashboard" />
                            </Switch>
                          </Suspense>
                        </GlobalAuthenticatedContexts>
                      )
                    ) : null
                  ) : // Logged Out View
                  this.state.loggedIn == false ? (
                    <Suspense fallback={<Loading />}>
                      <Switch>
                        <Route
                          path="/automations/templates/:templateSlug"
                          component={LoggedOutTemplatePreview}
                        />
                        <Route
                          path="/pspay-customer/:uniqueKey"
                          key={162}
                          render={(props) => (
                            <LegacyGlobalBannersProvider>
                              <LegacyGlobalBannerList />
                              <RedirectPage {...props} />
                            </LegacyGlobalBannersProvider>
                          )}
                        />
                        <Route
                          path="/login"
                          render={(props) => (
                            <LoginUserShopify
                              changeState={this.setGenericState}
                            />
                          )}
                        />
                        <Route
                          path="/login_email"
                          render={(props) => (
                            <LoginForm changeState={this.setGenericState} />
                          )}
                        />
                        <Route
                          path="/new_password"
                          render={(props) => (
                            <ResetForgottenPassword
                              changeState={this.setGenericState}
                            />
                          )}
                        />
                        <Route path="/shopify/init" component={AppInit} />
                        <Route
                          path="/shopify/auth"
                          render={(props) => (
                            <AppAuth
                              changeState={this.setGenericState}
                              getUserInfo={this.getUserInfo}
                            />
                          )}
                        />
                        <Route
                          path="/register_store"
                          render={() => (
                            <RegisterForm changeState={this.setGenericState} />
                          )}
                        />
                        <Route
                          path="/ref"
                          render={(props) => (
                            <ReferralRedirect
                              match={props.match}
                              changeState={this.setGenericState}
                            />
                          )}
                        />
                        <Route
                          path="/reset"
                          render={(props) => (
                            <ResetPasswordInivisible
                              getUserInfo={this.getUserInfo}
                              changeState={this.setGenericState}
                            />
                          )}
                        />
                        <Route
                          path="/request_reset"
                          render={(props) => <ResetPasswordRequest />}
                        />
                        <Route path="/yc" render={(props) => <YCLogin />} />
                        <Redirect to="/login" />
                      </Switch>
                    </Suspense>
                  ) : null}
                </Router>
              </SentryContext.Provider>
            </HistoryContext.Provider>
          </StripeProvider>
        </ThemeProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    );
  }
}

export default feedbackLoopRedirect(App);
export { history, UserContext };
