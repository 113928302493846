import {
  Button,
  Card,
  Icon,
  IconSet,
  Input,
  SelectMenu,
} from '@postscript/components';
import { getShopId } from 'components/account/AccountView/users/helpers';
import { Field, Form, Formik } from 'formik';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { api } from 'controllers/network/apiClient';
import {
  AgentDashboardResponse,
  useAgentDashboard,
} from '../hooks/useDashboard';

export interface Controls {
  page: number;
  shopIds: number[];
  agentIds: string[] | number[];
  searchText: string;
  searchDirection: string;
  filters: string[];
  topicLabel?: string;
  tag?: string;
  sortBy: string;
  dateRangeStart?: string;
  dateRangeEnd?: string;
  maxPageSize?: number;
}

interface Props {
  onControlsUpdate: (filters: any) => void;
  controls: Controls;
  isLoading: boolean;
  isMultiShop: boolean;
}

const StyledControlLayout = styled.div<{ isMultiShop: boolean }>`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
`;

const StyledControlCard = styled(Card)`
  border-radius: var(--border-radius-medium);
  background: var(--surface-bkg-color);
  box-shadow: var(--box-shadow-small);
  padding: var(--spacing-2);
  margin-bottom: var(--spacing-2);
  width: 100%;
`;

const StyledWrappingLayout = styled.div`
  display: flex;
  gap: var(--spacing-2);
  flex-wrap: wrap;
  align-items: flex-start;
`;

const IconCircle = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: var(--border-radius-round);
  background-color: var(--text-color);
`;

export const CREATED_AT_ASC = 'CREATED_AT_1';
export const CREATED_AT_DESC = 'CREATED_AT_-1';
export const MOST_RECENT_INBOUND = 'MOST_RECENT_INBOUND';
export const MOST_RECENT_OUTBOUND = 'MOST_RECENT_OUTBOUND';
const SORT_BY: any = {
  [CREATED_AT_ASC]: {
    label: 'Conversations (oldest First)',
    value: CREATED_AT_ASC,
  },
  [CREATED_AT_DESC]: {
    label: 'Conversations (newest First)',
    value: CREATED_AT_DESC,
  },
  [MOST_RECENT_INBOUND]: {
    label: 'Inbound message (newest first)',
    value: MOST_RECENT_INBOUND,
  },
  [MOST_RECENT_OUTBOUND]: {
    label: 'Outbound message (newest first)',
    value: MOST_RECENT_OUTBOUND,
  },
};

const INBOUND = 'INBOUND';
const OUTBOUND = 'OUTBOUND';
const ALL = 'ALL';

const SEARCH_DIRECTION: any = {
  [ALL]: {
    label: 'Search all messages',
    value: ALL,
  },
  [INBOUND]: {
    label: 'Search inbound only',
    value: INBOUND,
  },
  [OUTBOUND]: {
    label: 'Search outbound only',
    value: OUTBOUND,
  },
};

export const LIVE = 'LIVE';
export const INACTIVE = 'INACTIVE';
export const HAS_SUMMARY = 'HAS_SUMMARY';
export const NO_SUMMARY = 'NO_SUMMARY';
export const CONVERTED = 'CONVERTED';
export const NOT_CONVERTED = 'NOT_CONVERTED';
export const UNSUBSCRIBED = 'UNSUBSCRIBED';
export const POSITIVE = 'POSITIVE';
export const NEGATIVE = 'NEGATIVE';
export const NEUTRAL = 'NEUTRAL';

const CONVERSATION_STATUS: any = {
  [LIVE]: {
    label: 'Happening now',
    value: 'LIVE',
  },
  [INACTIVE]: {
    label: 'Inactive',
    value: 'INACTIVE',
  },
};

const SUMMARY: any = {
  [NO_SUMMARY]: {
    label: 'No Summary',
    value: NO_SUMMARY,
  },
};

const OUTCOMES: any = {
  [CONVERTED]: {
    label: 'Converted',
    value: CONVERTED,
  },
  [NOT_CONVERTED]: {
    label: 'Did not convert',
    value: NOT_CONVERTED,
  },
  [UNSUBSCRIBED]: {
    label: 'Unsubscribed',
    value: UNSUBSCRIBED,
  },
};

const SENTIMENT: any = {
  [POSITIVE]: {
    label: 'Positive',
    value: POSITIVE,
  },
  [NEUTRAL]: {
    label: 'Neutral',
    value: NEUTRAL,
  },
  [NEGATIVE]: {
    label: 'Negative',
    value: NEGATIVE,
  },
};

interface DropdownOption {
  label: string;
  value: string;
}

const getControlsFromValues = (
  { shops, agents, searchText, filters, sortBy, searchDirection }: any,
  isMultiShop: boolean,
  includeHasSummary: boolean,
) => {
  const shopIds = isMultiShop
    ? shops.map((shop: any) => shop.value)
    : [getShopId()];
  return {
    searchText,
    shopIds,
    agentIds: agents.map((agent: any) => agent.value),
    filters: (!filters.includes(HAS_SUMMARY) && includeHasSummary
      ? [...filters, HAS_SUMMARY]
      : filters
    ).map((filter: any) => {
      if (filter === '') {
        return '';
      }
      return filter !== HAS_SUMMARY ? filter.value : HAS_SUMMARY;
    }),
    sortBy: sortBy.value,
    searchDirection: searchDirection.value,
  };
};

const transformAgentData = (
  agentData: AgentDashboardResponse,
): DropdownOption[] => {
  const transformedData: DropdownOption[] = [];

  Object.keys(agentData.active_agents).forEach((agentId) => {
    const agentInfo = agentData.active_agents[parseInt(agentId)];

    transformedData.push({
      label: agentInfo.name,
      value: agentId,
    });
  });

  return transformedData;
};

const ConversationIntelligenceControls = ({
  controls,
  onControlsUpdate,
  isLoading,
  isMultiShop,
}: Props) => {
  const [shopSearchText, setShopSearchText] = useState('');
  const [agentSearchText, setAgentSearchText] = useState('');
  const { data: shops } = useQuery({
    queryKey: ['sms-sales-shops'],
    queryFn: (): Promise<{ shops: DropdownOption[] }> =>
      api.get('/admin/get_all_shops'),
    select: (data) => data.shops,
  });

  const { data: agentData } = useAgentDashboard();

  const agentOptions = useMemo(() => {
    if (!agentData) return [];

    const agentDropdownOptions = transformAgentData(agentData);

    return agentDropdownOptions.filter((agent) =>
      agent.label.includes(agentSearchText),
    );
  }, [agentData, agentSearchText]);

  const shopOptions = useMemo(() => {
    if (!shops) return [];

    const filterShops = (shop: DropdownOption) => {
      const shopName = shop.label?.toLowerCase();
      const shopId = shop.value.toString();
      const searchString = shopSearchText.toLowerCase();
      return (
        shopId.startsWith(searchString) || shopName?.includes(searchString)
      );
    };

    const getShopOption = (shop: DropdownOption) => ({
      label: `${shop.label} (${shop.value})`,
      value: shop.value,
    });

    return shops.filter(filterShops).map(getShopOption).slice(0, 50);
  }, [shops, shopSearchText]);

  const shopsMap: any = {};
  shopOptions.forEach(({ value, label }: { value: string; label: string }) => {
    shopsMap[value] = { value, label };
  });

  const agentsMap: any = {};
  agentOptions.forEach(({ value, label }: { value: string; label: string }) => {
    agentsMap[value] = { value, label };
  });

  const initialFilterValues = [
    ...controls.filters.map((filter) => CONVERSATION_STATUS[filter]),
    ...controls.filters.map((filter) => SUMMARY[filter]),
    ...controls.filters.map((filter) => OUTCOMES[filter]),
    ...controls.filters.map((filter) => SENTIMENT[filter]),
  ].filter((filter) => filter !== undefined);

  return (
    <Formik
      initialValues={{
        shops: shops
          ? shops.filter((shop) => controls.shopIds.includes(+shop.value))
          : [],
        agents: agentOptions
          ? controls.agentIds.map((agentId) => agentsMap[agentId])
          : [],
        searchText: controls.searchText,
        filters: initialFilterValues,
        sortBy: SORT_BY[controls.sortBy] || MOST_RECENT_INBOUND,
        searchDirection: SEARCH_DIRECTION[controls.searchDirection] || ALL,
      }}
      onSubmit={(values) => {
        const includeHasSummary = controls.filters.includes(HAS_SUMMARY);
        onControlsUpdate(
          getControlsFromValues(values, isMultiShop, includeHasSummary),
        );
      }}
    >
      {({ values, setFieldValue, submitForm, resetForm }) => (
        <Form>
          <StyledControlLayout isMultiShop={isMultiShop}>
            <StyledControlCard>
              <StyledControlLayout isMultiShop={isMultiShop}>
                {/* Search, Shops, Agents */}
                <StyledWrappingLayout>
                  <IconCircle>
                    <Icon
                      size="large"
                      color="var(--white)"
                      component={IconSet.Search}
                    />
                  </IconCircle>
                  <div style={{ flex: '1 0 calc(100% - 48%)' }}>
                    <Field
                      as={Input}
                      placeholder="Search"
                      name="searchText"
                      onChange={({ target }: any) => {
                        setFieldValue('searchText', target.value);
                      }}
                      type="text"
                      fieldWidth="100%"
                    />
                  </div>
                </StyledWrappingLayout>
                <Field
                  as={SelectMenu}
                  isClearable={false}
                  placeholder="Search Direction"
                  name="searchDirection"
                  value={values.searchDirection}
                  options={Object.values(SEARCH_DIRECTION)}
                  onChange={(value: any) => {
                    setFieldValue('searchDirection', value);
                  }}
                  fieldWidth="100%"
                />
                {isMultiShop && (
                  <Field
                    as={SelectMenu}
                    placeholder="Select Shops"
                    name="shops"
                    isMulti
                    value={values.shops}
                    options={shopOptions}
                    onChange={(value: any) => {
                      setFieldValue('shops', value);
                    }}
                    onInputChange={setShopSearchText}
                    fieldWidth="100%"
                  />
                )}
                {isMultiShop && (
                  <Field
                    as={SelectMenu}
                    placeholder="Select Agents"
                    name="agents"
                    isMulti
                    value={values.agents}
                    options={agentOptions}
                    onChange={(value: any) => {
                      setFieldValue('agents', value);
                    }}
                    onInputChange={setAgentSearchText}
                    fieldWidth="100%"
                  />
                )}

                {/* Filter, Sort */}
                <Field
                  as={SelectMenu}
                  placeholder="Filter"
                  name="filters"
                  isMulti
                  value={values.filters.filter(
                    (filter) => filter !== HAS_SUMMARY,
                  )}
                  options={[
                    {
                      label: 'Status',
                      options: Object.values(CONVERSATION_STATUS),
                    },
                    {
                      label: 'Summary',
                      options: Object.values(SUMMARY),
                    },
                    {
                      label: 'Outcomes',
                      options: Object.values(OUTCOMES),
                    },
                    {
                      label: 'Sentiment',
                      options: Object.values(SENTIMENT),
                    },
                  ]}
                  onChange={(value: any) => {
                    setFieldValue('filters', value);
                  }}
                  fieldWidth="100%"
                />
                <Field
                  as={SelectMenu}
                  isClearable={false}
                  placeholder="Sort"
                  name="sortBy"
                  value={values.sortBy}
                  options={Object.values(SORT_BY)}
                  onChange={(value: any) => {
                    setFieldValue('sortBy', value);
                  }}
                  fieldWidth="100%"
                />

                {/* Filter, Reset */}
                <StyledWrappingLayout style={{ justifyContent: 'flex-end' }}>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      resetForm();
                      setFieldValue('searchText', '');
                      setFieldValue('filters', [HAS_SUMMARY]);
                      submitForm();
                    }}
                  >
                    Clear
                  </Button>
                  <Button type="submit" disabled={isLoading}>
                    Search
                  </Button>
                </StyledWrappingLayout>
              </StyledControlLayout>
            </StyledControlCard>
          </StyledControlLayout>
        </Form>
      )}
    </Formik>
  );
};

export default ConversationIntelligenceControls;
