/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { toast } from '@postscript/components';
import { cloneDeep, isNil, uniqBy } from 'lodash';
import { formatErrorMessage } from '../../flowBuilder/utils/errors';
import { MULTIPLIER_FRACTION_DIGITS } from './constants';
import {
  Invoice,
  LedgerMetadata,
  LedgerRecord,
  LedgerRecordTypes,
  MessageRate,
  MessageRateInput,
  MessageTypes,
  RecurringFee,
  UsageCredit,
} from './types';

export const formatDollars = (
  amount: number,
  minimumFractionDigits = 2,
  maximumFractionDigits?: number,
): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits ?? minimumFractionDigits,
    currency: 'USD',
  }).format(parseFloat(amount.toString()));
};

export const formatInputDollars = (
  input: string,
  minimumFractionDigits = 2,
  maximumFractionDigits?: number,
  allowNegativeValues = true,
): string => {
  let a = input;
  a = (a || '0').toString().replace(/,/g, '');
  if (!allowNegativeValues) a = a.replace(/-/g, '');
  if (a.charAt(0) === '-' && a.charAt(1) === '$') a = `-${a.substr(2)}`;
  if (a.charAt(0) === '$') a = a.substr(1);
  if (Number.isNaN(parseFloat(a))) a = '0';
  a = parseFloat(a).toFixed(maximumFractionDigits ?? minimumFractionDigits);

  return formatDollars(
    parseFloat(a),
    minimumFractionDigits,
    maximumFractionDigits,
  );
};

export function formatInputMessageRate(rate: string): string {
  return formatInputDollars(rate, 0, MULTIPLIER_FRACTION_DIGITS, false);
}

export function getCents(value: string): number {
  let string = value;

  // Ensure value has at least two decimal places
  if (string.indexOf('.') === -1) {
    string = `${string}.00`;
  }

  // Remove "$" and commas
  string = string.replace(/[^0-9.-]/g, '');

  // Add trailing zeros to at least the one-thousandth place
  string = `${string}000`;

  // Grab indexes to offset decimal point
  const index = string.indexOf('.');
  const offset = 2;
  const offsetIndex = index + offset;

  // Move decimal point to the right by two places (get cents from dollars)
  string = string.replace('.', '');
  string = `${string.slice(0, offsetIndex)}.${string.slice(offsetIndex)}`;

  return parseFloat(string);
}

interface LedgerRecordFilterInput {
  ledgerRecordTypes?: LedgerRecordTypes[];
  descriptions?: string[];
  messageTypes?: MessageTypes[];
}

export function filterLedgerRecords(
  ledgerRecords: LedgerRecord[],
  filters: LedgerRecordFilterInput = {},
): LedgerRecord[] {
  const { ledgerRecordTypes, descriptions, messageTypes } = filters;

  return ledgerRecords
    .filter(({ type }) =>
      ledgerRecordTypes?.length ? ledgerRecordTypes.includes(type) : true,
    )
    .filter(({ description }) =>
      descriptions?.length ? descriptions.includes(description) : true,
    )
    .filter(({ ledgerMetadata }) =>
      messageTypes?.length
        ? messageTypes.includes(ledgerMetadata?.messageType as MessageTypes)
        : true,
    );
}

export function sumLedgerRecords(
  ledgerRecords: LedgerRecord[],
  filters: LedgerRecordFilterInput = {},
): number {
  const { ledgerRecordTypes, descriptions, messageTypes } = filters;

  return filterLedgerRecords(ledgerRecords, {
    ledgerRecordTypes,
    descriptions,
    messageTypes,
  })
    .map(({ amount }) => amount)
    .reduce((a, b) => a + b, 0);
}

export function countLedgerRecords(
  ledgerRecords: LedgerRecord[],
  filters: LedgerRecordFilterInput = {},
): number {
  const { ledgerRecordTypes, descriptions, messageTypes } = filters;

  return filterLedgerRecords(ledgerRecords, {
    ledgerRecordTypes,
    descriptions,
    messageTypes,
  }).length;
}

export function aggregateLedgerRecordsByType(
  ledgerRecords: LedgerRecord[],
  typesToAggregate: LedgerRecordTypes[],
): LedgerRecord[] {
  if (!ledgerRecords.length) return ledgerRecords;

  const typesSet = new Set(typesToAggregate);
  const aggregatedData = new Map<LedgerRecordTypes, LedgerRecord>();
  const nonAggregated: LedgerRecord[] = [];

  ledgerRecords.forEach((record) => {
    const { type, amount } = record;

    if (!typesSet.has(type)) {
      return nonAggregated.push(record);
    }

    const aggregatedRecord = aggregatedData.get(type);

    if (!aggregatedRecord) {
      return aggregatedData.set(type, { ...record });
    }

    aggregatedRecord.amount += amount;
  });

  return [...nonAggregated, ...aggregatedData.values()];
}

export function toggleButtonLoadingState({
  button,
  isLoading,
  originalText,
}: {
  button: HTMLButtonElement | null;
  isLoading: boolean;
  originalText: string;
}): void {
  if (!button) return;

  const mutableButton = button;

  if (isLoading) {
    mutableButton.disabled = true;
    mutableButton.innerText = 'Loading...';
    mutableButton.style.fontWeight = 'var(--body-text-bold-font-weight)';

    return;
  }

  mutableButton.innerText = originalText;
  mutableButton.disabled = false;
}

export function findMessageRateByCodeAndType(
  messageRates: MessageRate[] | undefined,
  countryCode: string,
  messageType: MessageTypes,
): number | undefined {
  const messageRate = messageRates?.find(
    (rate) =>
      rate.countryCode === countryCode && rate.messageType === messageType,
  );

  return messageRate?.messageRate;
}

export function formatMessageRate(amount: number): string {
  return formatDollars(amount / 100, 2, MULTIPLIER_FRACTION_DIGITS);
}

export function formatEstimatedCost(amount: number): string {
  return formatDollars(amount / 100, 2, 2);
}

export function convertMessageRatesToInputRates(
  messageRates: MessageRate[],
): MessageRateInput[] {
  const inputRates: MessageRateInput[] = [];

  const uniqueRatesByCountry = uniqBy(messageRates, 'countryCode');

  for (const rate of uniqueRatesByCountry) {
    const { countryCode } = rate;

    const getRateByType = (type: MessageTypes): number =>
      messageRates.find(
        (messageRate) =>
          messageRate.countryCode === countryCode &&
          messageRate.messageType === type,
      )?.messageRate || 0;

    inputRates.push({
      countryCode,
      formattedSmsRate: formatMessageRate(getRateByType('SMS')),
      formattedMmsRate: formatMessageRate(getRateByType('MMS')),
    });
  }

  return inputRates;
}

export function convertInputRatesToMessageRates(
  inputRates: MessageRateInput[],
): MessageRate[] {
  const messageRates = inputRates
    .map(({ countryCode, formattedSmsRate, formattedMmsRate }) => [
      {
        countryCode,
        messageRate: getCents(formattedSmsRate),
        messageType: 'SMS' as MessageTypes,
      },
      {
        countryCode,
        messageRate: getCents(formattedMmsRate),
        messageType: 'MMS' as MessageTypes,
      },
    ])
    .flat()
    .filter(({ messageRate }) => messageRate > 0);

  return messageRates;
}

export function getLedgerRecordsFromInvoices(
  invoices: Invoice[],
): LedgerRecord[] {
  return invoices.map(({ ledgerRecords }) => ledgerRecords).flat() || [];
}

export function findActiveUsageCredits(
  usageCredits: UsageCredit[],
): UsageCredit[] {
  return usageCredits
    .filter(({ isExpired }) => !isExpired)
    .filter(({ amountTotal, amountUsed }) => amountUsed < amountTotal);
}

export function sumUsageCredits(usageCredits: UsageCredit[]): {
  amountTotal: number;
  amountUsed: number;
} {
  return {
    amountTotal: usageCredits.reduce(
      (sum, { amountTotal }) => sum + amountTotal,
      0,
    ),
    amountUsed: usageCredits.reduce(
      (sum, { amountUsed }) => sum + amountUsed,
      0,
    ),
  };
}

export const toastError = (error: unknown) => {
  toast.error(formatErrorMessage(error));
};

function formatRevShareRecordDescription(
  recurringFee: RecurringFee,
  ledgerMetadata: LedgerMetadata,
): string {
  const orderType =
    recurringFee.type === 'SMS_SALES_REVENUE_SHARE_NEW' ? 'new' : 'returning';
  return `${ledgerMetadata.revenueSharePercentage}% of ${formatDollars(
    (ledgerMetadata.totalPretaxPrice ?? 0) / 100,
  )} total ${orderType} order attributed revenue`;
}

export function aggregateRevShareLedgerRecords(
  ledgerRecords: LedgerRecord[],
): LedgerRecord[] {
  const records = new Map<string, LedgerRecord>();

  cloneDeep(ledgerRecords).forEach((record) => {
    const { type, recurringFee, amount, ledgerMetadata } = record;

    if (
      type !== 'SMS_SALES_REVENUE_SHARE' ||
      !recurringFee ||
      ![
        'SMS_SALES_REVENUE_SHARE_NEW',
        'SMS_SALES_REVENUE_SHARE_REPEAT',
      ].includes(recurringFee.type) ||
      !ledgerMetadata ||
      isNil(ledgerMetadata?.totalPretaxPrice) ||
      isNil(ledgerMetadata?.revenueSharePercentage)
    ) {
      records.set(record.id.toString(), record);
      return;
    }

    const key = `${recurringFee.type}-${ledgerMetadata.revenueSharePercentage}`;
    const existingRecord = records.get(key);

    if (
      existingRecord?.ledgerMetadata &&
      existingRecord.recurringFee &&
      !isNil(existingRecord?.ledgerMetadata?.totalPretaxPrice)
    ) {
      existingRecord.amount += amount;
      existingRecord.ledgerMetadata.totalPretaxPrice +=
        ledgerMetadata.totalPretaxPrice ?? 0;
      existingRecord.recurringFee.description = formatRevShareRecordDescription(
        recurringFee,
        existingRecord.ledgerMetadata,
      );
      return;
    }

    recurringFee.name = `SMS Sales ${
      recurringFee.type === 'SMS_SALES_REVENUE_SHARE_NEW' ? 'new' : 'repeat'
    } order commission`;
    recurringFee.description = formatRevShareRecordDescription(
      recurringFee,
      ledgerMetadata,
    );
    records.set(key, record);
  });

  return Array.from(records.values());
}
