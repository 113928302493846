import { BodyText } from '@postscript/components';
import styled from 'styled-components';
import { formatDollars } from '../../common/utils';

interface Props {
  feeCents?: number;
  className?: string;
}

const StyledText = styled(BodyText).attrs({
  color: 'var(--text-color)',
})`
  font: var(--heading-small);
  letter-spacing: var(--heading-letter-spacing-small);
`;

export default function SubscriptionFee({
  feeCents,
  className,
}: Props): JSX.Element {
  return (
    <StyledText className={className} data-cy="plan-saas-fee">
      {feeCents !== undefined ? (
        <>{formatDollars(feeCents / 100, 0)}/mo</>
      ) : (
        'Contact sales'
      )}
    </StyledText>
  );
}
