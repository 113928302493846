import {
  DropdownButton,
  DropdownMenu,
  DropdownMenuItem,
} from '@postscript/components';
import { SMS_SALES_VIEW_LOCAL_STORAGE_KEY } from 'constants/constants';
import { useUser } from 'controllers/contexts/user';
import { VIEW_OPTIONS } from '../constants';
import { useView } from '../hooks/useView';
import { getMaxViewLevelForUser } from '../utils/roles';

export const ViewSelector = (): JSX.Element => {
  const { user } = useUser();
  const { view, setView } = useView();
  const maxViewLevel = getMaxViewLevelForUser(user);

  if (maxViewLevel === VIEW_OPTIONS.MERCHANT) {
    return <></>;
  }

  const availableOptions = [
    VIEW_OPTIONS.MERCHANT,
    VIEW_OPTIONS.SALES_ASSOCIATE,
  ];
  if (maxViewLevel === VIEW_OPTIONS.AI_DEBUGGER) {
    availableOptions.push(VIEW_OPTIONS.AI_DEBUGGER);
  }

  const dropdownMenuItems = availableOptions.map((option) => {
    return (
      <DropdownMenuItem>
        <button
          onClick={() => {
            setView(option);
            localStorage.setItem(SMS_SALES_VIEW_LOCAL_STORAGE_KEY, option);
          }}
          type="button"
        >
          {option}
        </button>
      </DropdownMenuItem>
    );
  });

  return (
    <DropdownButton
      buttonText={view}
      size="small"
      id="the-unique-id-of-the-button-info"
    >
      <DropdownMenu
        aria-labelledby="the-unique-id-of-the-button-info"
        menuAnchor="right"
      >
        {dropdownMenuItems}
      </DropdownMenu>
    </DropdownButton>
  );
};
