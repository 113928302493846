/* eslint-disable camelcase */
import { EstimatedCost } from 'components/billing/modules/plans/estimatedCampaignCost/types';
import { api } from 'controllers/network/apiClient';
import moment from 'moment';
import QueryString from 'qs';
import { DATEPICKER_DATE_FORMAT } from '../common/constants';
import {
  AvailablePaymentMethods,
  CreateUsageCreditInput,
  CustomPlanInput,
  Cycle,
  Cycles,
  CycleWithTotals,
  Invoice,
  InvoicesResponse,
  InvoicingSettings,
  InvoicingStatus,
  LedgerRecord,
  LedgerRecordInput,
  LedgerRecordStat,
  ManualPaymentReceiptInput,
  MinimumSpend,
  NewCustomProductPlan,
  Package,
  PaymentMethod,
  Plan,
  ProductPlan,
  ProductPlanTypes,
  RecurringFee,
  RecurringFeeInput,
  ScheduledPlanChange,
  SearchParams,
  StatsTotalResponse,
  UpdateInvoiceStatus,
  UsageCredit,
} from '../common/types';
import { getCents } from '../common/utils';

const url = '/v2/billing';

// Get minimum spend for cycle
export async function getMinimumSpend(cycleId: number): Promise<MinimumSpend> {
  return api.get(`${url}/plans/minimum_spend/${cycleId}`);
}

// Get all invoices for this shop
export async function getInvoices(
  search?: SearchParams,
): Promise<InvoicesResponse> {
  return api.get(
    `${url}/invoices/shop_invoices?${QueryString.stringify(search)}`,
  );
}

// Get usage stats for this shop
export async function getUsageStats({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}): Promise<LedgerRecordStat[]> {
  const { statistics } = await api.get(
    `${url}/usage/stats?start_date=${startDate}&end_date=${endDate}`,
  );
  return statistics;
}

// Get all invoices for a given cycle
export async function getInvoicesByCycle(cycleId: number): Promise<Invoice[]> {
  const { shopInvoices } = await api.get(
    `${url}/plans/cycle/${cycleId}/invoices`,
  );
  return shopInvoices;
}

// Get invoice by invoice number
export async function getInvoiceByNumber(
  invoiceNumber: string,
): Promise<Invoice> {
  return api.get(`${url}/invoices/shop_invoice/${invoiceNumber}`);
}

// Get all cycles (plus the total of all invoices and the number of unpaid invoices) that have at least one invoice
export async function getCyclesWithTotals(): Promise<CycleWithTotals[]> {
  const { cycles } = await api.get(`${url}/plans/cycles/invoices_total`);
  return cycles;
}

// Usage: Carrier Fees + Messaging Fees
// Get the amount of usage so far that will be billed on the next invoice
export async function getUninvoicedUsageAmount(): Promise<number> {
  const { amountTotalCents } = await api.get(
    `${url}/usage/outstanding/next_invoice`,
  );
  return amountTotalCents;
}

// Get outstanding (un-invoiced) ledger records for this shop
export async function getUninvoicedLedgerRecords(): Promise<LedgerRecord[]> {
  const { ledgerRecords } = await api.get(
    `${url}/core/ledger_records/outstanding`,
  );
  return ledgerRecords;
}

// Get all Usage Credits for this shop
export async function getUsageCredits(): Promise<UsageCredit[]> {
  const { usageCredits } = await api.get(`${url}/usage/usage_credits`);
  return usageCredits;
}

// Deactivate a Usage Credit by ID
export async function deactivateUsageCredit(id: number): Promise<void> {
  await api.post(`${url}/admin/usage/usage_credits/${id}/deactivate`);
}

// Create a Usage Credit
export async function createUsageCredit(
  usageCreditInput: CreateUsageCreditInput,
): Promise<void> {
  await api.post(`${url}/admin/usage/usage_credits`, usageCreditInput);
}

// Get all marketing plans available for this shop
export async function getMarketingPlans(): Promise<Plan[]> {
  const { plans } = await api.get(`${url}/plans/available_plans`);
  return plans;
}

// Get all product plans available for this shop
export async function getProductPlans(
  type: ProductPlanTypes,
): Promise<ProductPlan[]> {
  const { products } = await api.get(`${url}/plans/products`);
  return products.filter(
    ({ productType }: ProductPlan) => productType === type,
  );
}

// Get all current product plans for this shop
export async function getCurrentProductPlans(
  type: ProductPlanTypes,
): Promise<ProductPlan[]> {
  const { products } = await api.get(`${url}/plans/products/current`);
  return products.filter(
    ({ productType }: ProductPlan) => productType === type,
  );
}

// Assign a product plan to this shop
export async function assignProductPlan(id: number): Promise<void> {
  return api.post(`${url}/admin/plans/products/assign`, { id });
}

// Remove an SMS Sales product plan from this shop
export async function removeSalesProductPlan(id: number): Promise<void> {
  return api.post(`${url}/admin/plans/products/sms_sales/remove`, { id });
}

// Get all custom marketing plans for this shop
export async function getCustomMarketingPlans(): Promise<Plan[]> {
  const { plans } = await api.get(`${url}/plans/custom`);
  return plans;
}

// Get all packages available to this shop
export async function getPackages(): Promise<Package[]> {
  const { packages } = await api.get(`${url}/plans/shop_packages`);
  return packages;
}

// Get all available payment methods for this shop
export async function getAvailablePaymentMethods(): Promise<AvailablePaymentMethods> {
  return api.get(`${url}/payments/available_payment_methods`);
}

// Set available payment methods for this shop
export async function setAvailablePaymentMethods(
  availablePaymentMethods: AvailablePaymentMethods,
): Promise<void> {
  return api.post(
    `${url}/payments/available_payment_methods`,
    availablePaymentMethods,
  );
}

// Get uninvoiced usage statistics
export async function getUninvoicedUsageStats(): Promise<LedgerRecordStat[]> {
  const { statistics } = await api.get('/v2/billing/usage/outstanding');
  return statistics;
}

export async function getLedgerRecords(
  search?: SearchParams,
): Promise<LedgerRecord[]> {
  const { ledgerRecords } = await api.get(
    `/v2/billing/core/ledger_records?${QueryString.stringify(search)}`,
  );
  return ledgerRecords;
}

export async function getRecurringFees(
  search?: SearchParams,
): Promise<RecurringFee[]> {
  const { fees } = await api.get(
    `/v2/billing/recurring_fees/fees?${QueryString.stringify(search)}`,
  );
  return fees;
}

export async function createRecurringFee(
  recurringFee: RecurringFeeInput,
): Promise<void> {
  const { formattedAmount, startDate, endDate, hasEndDate } = recurringFee;

  // TODO: refactor to accept the inputs that the API expects; the form submission should handle the trasnformation of data
  await api.post('/v2/billing/recurring_fees/fees', {
    ...recurringFee,
    amountCents: getCents(formattedAmount),
    startDate: moment.utc(startDate).format(DATEPICKER_DATE_FORMAT),
    endDate: hasEndDate
      ? moment.utc(endDate).format(DATEPICKER_DATE_FORMAT)
      : undefined,
  });
}

export async function updateRecurringFee({
  id,
  recurringFee,
}: {
  id: number;
  recurringFee: RecurringFeeInput;
}): Promise<void> {
  const { formattedAmount, startDate, endDate, hasEndDate } = recurringFee;

  // TODO: refactor to accept the inputs that the API expects; the form submission should handle the trasnformation of data
  await api.patch(`/v2/billing/recurring_fees/fees/${id}`, {
    ...recurringFee,
    amountCents: getCents(formattedAmount),
    startDate: moment(startDate).format(DATEPICKER_DATE_FORMAT),
    endDate: hasEndDate
      ? moment(endDate).format(DATEPICKER_DATE_FORMAT)
      : undefined,
  });
}

export async function disableRecurringFee(id: number): Promise<void> {
  await api.delete(`/v2/billing/recurring_fees/fees/${id}`);
}

export async function getScheduledPlanChange(): Promise<ScheduledPlanChange | null> {
  const { scheduledPlanChange } = await api.get(
    `${url}/plans/scheduled_plan_change`,
  );
  return scheduledPlanChange;
}

// Used for admin-initiated scheduled plan changes
export async function schedulePlanChange({
  planId,
  changeDate,
}: ScheduledPlanChange): Promise<void> {
  return api.post(`${url}/plans/scheduled_plan_change`, { planId, changeDate });
}

// Used to delete admin-initiated scheduled plan change
export async function cancelScheduledPlanChange(): Promise<void> {
  return api.delete(`${url}/admin/plans/scheduled_plan_change`);
}

export async function getCurrentPlan(): Promise<Plan> {
  const { plan } = await api.get('/v2/billing/plans/current');
  return plan;
}

// Shop-initiated upgrades (will trigger a pro-rated platform fee when applicable)
export async function setCurrentPlan(planId: number): Promise<void> {
  await api.post('/v2/billing/plans/cycle/current', {
    planId,
  });
}

// Admin-initiated upgrades or downgrades (will not trigger a prorated platform fee)
export async function forceSetCurrentPlan(planId: number): Promise<void> {
  await api.put('/v2/billing/admin/plans/cycles/current', {
    planId,
  });
}

// From next cycle; will only be affected by a scheduled plan change if the changeDate is the first day of the next month (cycle)
export async function getNextPlan(): Promise<Plan> {
  const { plan } = await api.get('/v2/billing/plans/next');
  return plan;
}

// Shop-initiated downgrades
export async function setNextPlan(planId: number): Promise<void> {
  await api.post('/v2/billing/plans/cycle/next', {
    planId,
  });
}

export async function getCurrentAndNextCycles(): Promise<Cycles> {
  const { current, next } = await api.get(
    '/v2/billing/plans/cycles/current_and_next',
  );
  return { currentCycle: current, nextCycle: next };
}

export async function getPastCycles(): Promise<Cycle[]> {
  const { cycles } = await api.get('/v2/billing/plans/cycles/');
  return cycles;
}

export async function recordManualInvoicePayment(
  payment: ManualPaymentReceiptInput,
): Promise<void> {
  return api.post(
    `${url}/invoices/shop_invoices/${payment.id}/manual_payment`,
    {
      ...payment,
    },
  );
}

export async function getEstimatedCostsByCountryCode(
  hasMediaUrl: boolean,
  segmentId: number,
  textBody: string,
): Promise<EstimatedCost[]> {
  const {
    estimatedCostsByCountryCode,
  }: { estimatedCostsByCountryCode: EstimatedCost[] } = await api.post(
    `/v2/billing/segments/estimated_cost`,
    { hasMediaUrl, segmentId, textBody },
  );
  return estimatedCostsByCountryCode;
}

export async function updateMaxFailedInvoices(
  numOfInvoices: number,
): Promise<{ failedInvoicesForPastDue: number }> {
  const { failedInvoicesForPastDue } = await api.put(
    '/v2/billing/invoices/settings/failed_invoices_for_past_due',
    { failedInvoicesForPastDue: numOfInvoices },
  );
  return failedInvoicesForPastDue;
}

export async function getInvoicingStatus(): Promise<InvoicingStatus> {
  return api.get('/v2/billing/invoices/status');
}

export async function getPaymentMethod(): Promise<PaymentMethod> {
  return api.get('/v2/billing/payments/payment_method');
}

export async function moveAccountToStripe(): Promise<PaymentMethod> {
  return api.put('/v2/billing/payments/stripe');
}

export async function setCustomPlan(plan: CustomPlanInput): Promise<Plan> {
  return api.post('/v2/billing/admin/plans/custom', plan);
}

export async function createLedgerRecord(
  record: LedgerRecordInput,
): Promise<void> {
  return api.post('/v2/billing/core/ledger_records', record);
}

export async function removeCustomPlan(planId: number): Promise<void> {
  return api.delete(`/v2/billing/admin/plans/custom/${planId}`);
}

export async function getInvoicingSettings(): Promise<InvoicingSettings> {
  return api.get('/v2/billing/invoices/settings');
}

export async function updateInvoicingSettings(
  invoicingSettings: InvoicingSettings,
): Promise<InvoicingSettings> {
  return api.put('/v2/billing/invoices/settings', invoicingSettings);
}

// Sum given field of stats in date range
export async function getUsageStatsTotalsByField({
  fieldName,
  startDate,
  endDate,
}: {
  fieldName: string;
  startDate: string;
  endDate: string;
}): Promise<StatsTotalResponse> {
  return api.get(
    `${url}/usage/stats/total/${fieldName}?start_date=${startDate}&end_date=${endDate}`,
  );
}

export async function payInvoice(invoiceId: number): Promise<Invoice> {
  return api.post(`/v2/billing/invoices/shop_invoices/${invoiceId}/charge`);
}

export async function updateInvoiceStatus({
  invoiceId,
  reason,
  status,
}: UpdateInvoiceStatus): Promise<void> {
  return api.post(`/v2/billing/invoices/shop_invoices/${invoiceId}/status`, {
    reason,
    status,
  });
}

export async function getEstimatedSalesTaxAmount(): Promise<number> {
  const { amount } = await api.get(`${url}/invoices/taxes/estimate`);
  return amount;
}

export async function createCustomProductPlan(
  customPlan: NewCustomProductPlan,
): Promise<ProductPlan> {
  return api.post(`${url}/admin/plans/products/custom`, { ...customPlan });
}
