import { StyleElementName, TextElementType } from './types';

export const BLOCK_TYPES = {
  BUTTON: 'BUTTON',
  CLOSE_BUTTON: 'CLOSE_BUTTON',
  CONTAINER: 'CONTAINER',
  EMAIL_INPUT: 'EMAIL_INPUT',
  HEADING: 'HEADING',
  IMAGE: 'IMAGE',
  OTP_INPUT: 'OTP_INPUT',
  PHONE_INPUT: 'PHONE_INPUT',
  ROOT: 'ROOT',
  TEASER: 'TEASER',
  TEASER_ROOT: 'TEASER_ROOT',
  TEXT: 'TEXT',
} as const;

export const OPT_IN_METHODS = {
  ONE_TIME_PASSCODE: 'ONE_TIME_PASSCODE',
} as const;

export const SCREEN_SIZES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
} as const;

export const STEP_ROLES = {
  DEFAULT: 'DEFAULT',
  ONSITE_PHONE: 'ONSITE_PHONE',
  ONSITE_VERIFY: 'ONSITE_VERIFY',
} as const;

/*
  Content
*/
export const BUTTON_TEXT_ELEMENT = {
  BUTTON: 'button',
} as const;

export const HEADING_TEXT_ELEMENT = {
  HEADING: 'heading',
} as const;

export const LABEL_TEXT_ELEMENT = {
  LABEL: 'label',
} as const;

export const PARAGRAPH_TEXT_ELEMENT = {
  PARAGRAPH: 'paragraph',
} as const;

export const TEXT_ELEMENT_TYPES = {
  ...BUTTON_TEXT_ELEMENT,
  ...HEADING_TEXT_ELEMENT,
  ...LABEL_TEXT_ELEMENT,
  ...PARAGRAPH_TEXT_ELEMENT,
};

/*
  Styles
*/
export const BLOCK_STYLE_ELEMENT = {
  BLOCK: 'block',
} as const;

export const BUTTON_STYLE_ELEMENT = {
  BUTTON: 'button',
} as const;

export const HEADING_STYLE_ELEMENT = {
  HEADING: 'heading',
} as const;

export const IMAGE_STYLE_ELEMENT = {
  IMAGE: 'image',
} as const;

export const INPUT_STYLE_ELEMENT = {
  INPUT: 'input',
} as const;

export const LABEL_STYLE_ELEMENT = {
  LABEL: 'label',
} as const;

export const LINK_STYLE_ELEMENT = {
  LINK: 'link',
} as const;

export const SMALL_STYLE_ELEMENT = {
  SMALL: 'small',
} as const;

export const SVG_STYLE_ELEMENT = {
  SVG: 'svg',
} as const;

export const TEXT_STYLE_ELEMENT = {
  TEXT: 'text',
} as const;

export const STYLE_ELEMENT_TYPES = {
  ...BLOCK_STYLE_ELEMENT,
  ...BUTTON_STYLE_ELEMENT,
  ...HEADING_STYLE_ELEMENT,
  ...IMAGE_STYLE_ELEMENT,
  ...INPUT_STYLE_ELEMENT,
  ...LABEL_STYLE_ELEMENT,
  ...LINK_STYLE_ELEMENT,
  ...SMALL_STYLE_ELEMENT,
  ...SVG_STYLE_ELEMENT,
  ...TEXT_STYLE_ELEMENT,
};

export const STYLE_RULE_NAMES = {
  ALIGN_ITEMS: 'alignItems',
  ALIGN_SELF: 'alignSelf',
  BACKGROUND_COLOR: 'backgroundColor',
  BACKGROUND_IMAGE: 'backgroundImage',
  BACKGROUND_SIZE: 'backgroundSize',
  BLOCK_SIZE: 'blockSize',
  BORDER_COLOR: 'borderColor',
  BORDER_RADIUS: 'borderRadius',
  BORDER_STYLE: 'borderStyle',
  BORDER_WIDTH: 'borderWidth',
  COLOR: 'color',
  DISPLAY: 'display',
  FLEX: 'flex',
  FLEX_GROW: 'flexGrow',
  FONT_FAMILY: 'fontFamily',
  FONT_SIZE: 'fontSize',
  FONT_STYLE: 'fontStyle',
  FONT_WEIGHT: 'fontWeight',
  HEIGHT: 'height',
  INSET: 'inset',
  JUSTIFY_CONTENT: 'justify-content',
  LAYOUT_DIRECTION: 'layoutDirection',
  LAYOUT_SPACING: 'layoutSpacing',
  LETTER_SPACING: 'letterSpacing',
  LINE_HEIGHT: 'lineHeight',
  MARGIN: 'margin',
  MAX_HEIGHT: 'maxHeight',
  MAX_WIDTH: 'maxWidth',
  MIN_HEIGHT: 'minHeight',
  MIN_WIDTH: 'minWidth',
  OBJECT_FIT: 'objectFit',
  OPACITY: 'opacity',
  PADDING: 'padding',
  POSITION: 'position',
  TEXT_ALIGN: 'textAlign',
  TEXT_DECORATION: 'textDecoration',
  TEXT_TRANSFORM: 'textTransform',
  TEXT_WRAP: 'textWrap',
  VARIANT: 'variant',
  WIDTH: 'width',
  Z_INDEX: 'zIndex',
};

export const DEFAULT_COLORS = {
  BORDER: '#000000',
  INPUT_BG: '#ECECEC',
  POPUP_BG: '#FFFFFF',
  PRIMARY_BUTTON_BG: '#656565',
  PRIMARY_BUTTON_TEXT: '#FFFFFF',
  SECONDARY_BUTTON_BG: 'transparent',
  SECONDARY_BUTTON_TEXT: '#656565',
  TEASER_CLOSE_BUTTON_BG: 'rgba(255,255,255, 0.8)',
  TEXT: '#000000',
} as const;

export const SYSTEM_FONT_FAMILIES = {
  ARIAL: 'Arial',
  CENTURY_GOTHIC: 'Century Gothic',
  COURIER: 'Courier',
  GEORGIA: 'Georgia',
  HELVETICA: 'Helvetica',
  VERDANA: 'Verdana',
} as const;

// TODO(Kameron Ahler): this should probably be plural
export const SYSTEM_FONT_FAMILY_STYLE = {
  ARIAL: `'Arial', sans-serif`,
  CENTURY_GOTHIC: `'Century Gothic', sans-serif`,
  COURIER: `'Courier', monospace`,
  GEORGIA: `'Georgia', serif`,
  HELVETICA: `'Helvetica', sans-serif`,
  VERDANA: `'Verdana', sans-serif`,
} as const;

export const GOOGLE_FONT_FAMILIES = {
  ROBOTO: 'Roboto',
  OPEN_SANS: 'Open Sans',
  LATO: 'Lato',
  MONTSERRAT: 'Montserrat',
  OSWALD: 'Oswald',
  POPPINS: 'Poppins',
  RUBIK: 'Rubik',
  QUICKSAND: 'Quicksand',
  PLAYFAIR_DISPLAY: 'Playfair Display',
  FRAUNCES: 'Fraunces',
} as const;

export const GOOGLE_FONT_FAMILY_STYLES = {
  ROBOTO: `'${GOOGLE_FONT_FAMILIES.ROBOTO}', sans-serif`,
  OPEN_SANS: `'${GOOGLE_FONT_FAMILIES.OPEN_SANS}', sans-serif`,
  LATO: `'${GOOGLE_FONT_FAMILIES.LATO}', sans-serif`,
  MONTSERRAT: `'${GOOGLE_FONT_FAMILIES.MONTSERRAT}', sans-serif`,
  OSWALD: `'${GOOGLE_FONT_FAMILIES.OSWALD}', sans-serif`,
  POPPINS: `'${GOOGLE_FONT_FAMILIES.POPPINS}', sans-serif`,
  RUBIK: `'${GOOGLE_FONT_FAMILIES.RUBIK}', sans-serif`,
  QUICKSAND: `'${GOOGLE_FONT_FAMILIES.QUICKSAND}', sans-serif`,
  PLAYFAIR_DISPLAY: `'${GOOGLE_FONT_FAMILIES.PLAYFAIR_DISPLAY}', serif`,
  FRAUNCES: `'${GOOGLE_FONT_FAMILIES.FRAUNCES}', serif`,
} as const;

export const STYLE_RULE_NAMES_TO_CSS = {
  [STYLE_RULE_NAMES.ALIGN_ITEMS]: 'align-items',
  [STYLE_RULE_NAMES.ALIGN_SELF]: 'align-self',
  [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'background-color',
  [STYLE_RULE_NAMES.BACKGROUND_IMAGE]: 'background-image',
  [STYLE_RULE_NAMES.BACKGROUND_SIZE]: 'background-size',
  [STYLE_RULE_NAMES.BLOCK_SIZE]: 'block-size',
  [STYLE_RULE_NAMES.BORDER_COLOR]: 'border-color',
  [STYLE_RULE_NAMES.BORDER_RADIUS]: 'border-radius',
  [STYLE_RULE_NAMES.BORDER_STYLE]: 'border-style',
  [STYLE_RULE_NAMES.BORDER_WIDTH]: 'border-width',
  [STYLE_RULE_NAMES.COLOR]: 'color',
  [STYLE_RULE_NAMES.DISPLAY]: 'display',
  [STYLE_RULE_NAMES.FLEX]: 'flex',
  [STYLE_RULE_NAMES.FLEX_GROW]: 'flex-grow',
  [STYLE_RULE_NAMES.FONT_FAMILY]: 'font-family',
  [STYLE_RULE_NAMES.FONT_SIZE]: 'font-size',
  [STYLE_RULE_NAMES.FONT_STYLE]: 'font-style',
  [STYLE_RULE_NAMES.FONT_WEIGHT]: 'font-weight',
  [STYLE_RULE_NAMES.HEIGHT]: 'height',
  [STYLE_RULE_NAMES.INSET]: 'inset',
  [STYLE_RULE_NAMES.JUSTIFY_CONTENT]: 'justify-content',
  [STYLE_RULE_NAMES.LETTER_SPACING]: 'letter-spacing',
  [STYLE_RULE_NAMES.LINE_HEIGHT]: 'line-height',
  [STYLE_RULE_NAMES.MARGIN]: 'margin',
  [STYLE_RULE_NAMES.MAX_HEIGHT]: 'max-height',
  [STYLE_RULE_NAMES.MAX_WIDTH]: 'max-width',
  [STYLE_RULE_NAMES.MIN_HEIGHT]: 'min-height',
  [STYLE_RULE_NAMES.MIN_WIDTH]: 'min-width',
  [STYLE_RULE_NAMES.OBJECT_FIT]: 'object-fit',
  [STYLE_RULE_NAMES.OPACITY]: 'opacity',
  [STYLE_RULE_NAMES.PADDING]: 'padding',
  [STYLE_RULE_NAMES.POSITION]: 'position',
  [STYLE_RULE_NAMES.TEXT_ALIGN]: 'text-align',
  [STYLE_RULE_NAMES.TEXT_DECORATION]: 'text-decoration',
  [STYLE_RULE_NAMES.TEXT_TRANSFORM]: 'text-transform',
  [STYLE_RULE_NAMES.TEXT_WRAP]: 'text-wrap',
  [STYLE_RULE_NAMES.WIDTH]: 'width',
  [STYLE_RULE_NAMES.Z_INDEX]: 'z-index',
} as any;

export const StyleElementsToTextElements: Partial<
  Record<StyleElementName, TextElementType>
> = {
  [BUTTON_STYLE_ELEMENT.BUTTON]: BUTTON_TEXT_ELEMENT.BUTTON,
  [HEADING_STYLE_ELEMENT.HEADING]: HEADING_TEXT_ELEMENT.HEADING,
  [LABEL_STYLE_ELEMENT.LABEL]: LABEL_TEXT_ELEMENT.LABEL,
  [TEXT_STYLE_ELEMENT.TEXT]: PARAGRAPH_TEXT_ELEMENT.PARAGRAPH,
};

export const RESERVED_DATA_NAMES = {
  EMAIL: 'email',
  PHONE: 'phone',
  OTP: 'otp',
} as const;

export const CLOSE_BEHAVIORS = {
  HARD_CLOSE: 'HARD_CLOSE',
  TEASER: 'TEASER',
} as const;

// This is <postscript-popup> > div#postscript-popup, the highest div in tree
export const POPUP_WRAPPER_ID = 'postscript-popup';

export const CONFIG_ONCLICK_ACTIONS = {
  CLOSE: 'close',
  ROUTE_TO_STEP: 'routeToStep',
  SUBMIT_AND_ROUTE_TO_STEP: 'submitAndRouteToStep',
} as const;

export const CONFIG_ONCLICK_ROUTE_DIRECTIONS = {
  NEXT: 'next',
  PREVIOUS: 'previous',
} as const;

export const TRIGGER_TYPES = {
  DELAY: 'Delay',
} as const;

export const SUBSCRIBER_COHORTS = {
  NEW_SMS_USERS: 'NEW_SMS_USERS',
  EVERYONE: 'EVERYONE',
  EXISTING_SMS_SUBSCRIBERS: 'EXISTING_SMS_SUBSCRIBERS',
} as const;

export const BLOCK_POPUP_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const;

export const INCLUSION_CRITERIA_OPTIONS = {
  INCLUDED: 'INCLUDED',
  EXCLUDED: 'EXCLUDED',
} as const;

export const OTP_VERIFY_ERROR_STYLES = `background-color: #FFE5EA; color: #AA1E39; display: block; font-family: 'Inter', sans-serif; font-size: 10px; line-height: 12px; margin-block: 8px; padding: 3px 6px; text-align: left;`;

export const OTP_LENGTH_ERROR_MESSAGE = 'Please enter the complete code';
export const PHONE_REQUIRED_ERROR_MESSAGE = 'Please enter a valid phone number';
export const GENERAL_REQUIRED_ERROR_MESSAGE = 'This field is required';
export const DISCLAIMER_TERMS_TAG = '{terms_of_service}';
export const DISCLAIMER_PRIVACY_TAG = '{privacy_policy}';
